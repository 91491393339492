import { Component, OnInit } from '@angular/core';
import Parse from 'parse';
import { CookieService } from '../cookie.service';
import { ModalController, AlertController } from '@ionic/angular';

@Component({
    selector: 'app-adminProdModal',
    templateUrl: './adminProdModal.page.html',
    styleUrls: ['./adminProdModal.page.scss'],
})
export class AdminProdModalPage implements OnInit {

    product: any;

  prodName: string;
  prodDesc: string;
  prodPrice: number;
  prodImg: any;
  prodFlavors: any = [];
  prodIngredients: any = [];
  prodDetails: any = [];
    prodAllowExtras: any = [];
    prodAllowSyrups: boolean;
    prodAllowGelados: boolean;
    prodAllowToppings: boolean;
    prodAllowCookieCups: boolean;
    prodAllowMods: any = []; 
    prodIsActive: boolean;
    prodIsVendorAllowed: boolean;
  prodCategoryId: string;
  prodCategoryName: string;

  categories: any = [];
  newIngredients: any = [];
  newFlavors: any = [];

  uploading: boolean = false;
  deleting: boolean = false;
    newProdDetails: any = [];
    modifiers: any = [];

  constructor(
    private modalCtrl: ModalController,
    private service: CookieService,
    private alertCtrl: AlertController
  ) { }

  ngOnInit() {
    this.getCategories();
    this.getProdDetails();
    this.getModifiers();
  }

    async getProdDetails() { 

    let query = new Parse.Query('Products');
    query.equalTo('objectId', this.service.adminSelectedProd.id);
    query.include('category');
    query.include('allowModifiers');
    this.product = await query.first();

    this.prodName = this.product.get('name');
    this.prodDesc = this.product.get('description'); 
    this.prodImg = this.product.get('image');
    this.prodFlavors = this.product.get('flavors');
    this.prodIngredients = this.product.get('ingredients');
    this.prodDetails = this.product.get('details'); 
    this.prodPrice = this.prodDetails[0].price;
    this.prodAllowMods = this.product.get('allowModifiers'); 
    this.prodAllowExtras = this.product.get('allowExtras');
    this.prodCategoryId = this.product.get('category').id;
    this.prodIsActive = this.product.get('available');
    this.prodIsVendorAllowed = this.product.get('vendorProduct');
    this.prodCategoryName = this.product.get('category')?.get('name'); 
 }
      
  async getModifiers() {
      let query = new Parse.Query('Modifiers');
      this.modifiers = await query.find(); 
  }

  pushNewMod(i:number) {
      for (let k = 0; k < this.prodAllowMods.length; k++) {
          if (this.prodAllowMods[k].pointer.id == this.modifiers[i].id) {
              this.service.presentToast('Modificador ya esta añadido');
              return;
          }
      } 
      this.prodAllowMods.push(
          {
              "pointer": {
                  "__type": "Pointer",
                  "className": "Modifiers",
                  "objectId": this.modifiers[i].id
              },
              "name": this.modifiers[i].get('name')
          }
      ) 
  }

  removeModifier(i:number) {
      this.prodAllowMods.splice(i,1)
  }

  pushNewXtra(i:number) {
      for (let k = 0; k < this.prodAllowExtras.length; k++) {
          if (this.prodAllowExtras[k].pointer.id == this.modifiers[i].id) {
              this.service.presentToast('Extra ya esta añadido');
              return;
          }
      }
      this.prodAllowExtras.push(
          {
              "pointer": {
                  "__type": "Pointer",
                  "className": "Modifiers",
                  "objectId": this.modifiers[i].id
              },
              "name": this.modifiers[i].get('name')
          }
      )
  }

  removeExtra(i:number) {
    this.prodAllowExtras.splice(i, 1) 
  }

  closeModal(){
    this.modalCtrl.dismiss();
  }

  changeName(value){
    this.prodName = value;
  }

  async getCategories(){
    let query = new Parse.Query('Categories');
    this.categories = await query.find();
  }

  changeProdCat(id:string){
    this.prodCategoryId = id; 
  }

  initUpload(){
    var input = document.getElementById('adminProdModalPic');
    input.click();
  }

  picChange(event: any){
  var files = event.srcElement.files 
  let file = event.target.files[0]
  this.uploading = true;

  let reader = new FileReader()

  reader.onload = (e:any)=>{
    let fileData = e.target.result
        
        const base64Image = fileData;
              const name = 'photo.jpeg';
              const parseFile = new Parse.File(name, {
                  base64: base64Image
              }); 

              parseFile.save().then((savedFile) => {
                  this.prodImg = savedFile; 
                  this.uploading = false; 
              }).catch(error =>{
                console.log(error);
                this.service.presentToast(error.message);
                this.uploading = false;
              })
        }
      reader.readAsDataURL(file)
  }


  async saveProduct() {
    
    this.uploading = true;
    this.product.set('name', this.prodName);
    this.product.set('description', this.prodDesc);
    this.product.set('allowModifiers', this.prodAllowMods);
    this.product.set('allowExtras', this.prodAllowExtras);
    this.product.set('available', this.prodIsActive);
    this.product.set('vendorProduct', this.prodIsVendorAllowed);
    this.product.set('image', this.prodImg);
    this.product.set('category', Parse.Object.extend('Categories').createWithoutData(this.prodCategoryId).toPointer())
    
    var product = await this.product.save();
    let query = new Parse.Query('Products');
    query.equalTo('objectId', product.id);
    query.include('category');

    this.product = await query.first(); 
    this.service.presentToast('Producto actualizado!'); 
    this.uploading = false; 
  }

  async confirmProdRemoval(){
    const alert = await this.alertCtrl.create({
      cssClass: 'alertClass',
      message: 'Remover ' + this.product.get('name') +' como producto?',
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          cssClass: 'secondary',
          id: 'cancel-button',
          handler: () => {
        
          }
        }, {
          text: 'Si',
          id: 'confirm-button',
          handler: () => {
            this.deleting = true;
           //this.removeProduct();
          }
        }
      ]
    });

    await alert.present();
  }
 

  updateFlavors(value, i){ 
     console.log(this.prodFlavors); 
    this.prodFlavors.splice(i,1,value);
    console.log(this.prodFlavors);
    this.service.presentToast('Sabor actualizado') 
  }

  addInputFlavor(){
    this.newFlavors.length += 1;
  }

  addFlavor(value){
    if(!value){
      this.service.presentToast('Favor proveer un nombre para el sabor');
      return;
    }
    this.prodFlavors.push(value);
    this.newFlavors.length -= 1;
    this.service.presentToast('Sabor nuevo añadido'); 
  }
  removeNewFlavor(i){
    this.newFlavors.splice(i,1)
  }

  addNewDetails(){
    // this.newProdDetails.push({amount:'',price:''});
     this.prodDetails.push({amount:'',price:''});
  }
  removeNewDetail(i){
    this.newProdDetails.splice(i,1)
  }

  async alertRemoveProdDetail(i){
    const alert = await this.alertCtrl.create({
          cssClass: 'alertClass',
          header: 'Remover?',
          buttons: [
            {
              text: 'Cancel',
              role: 'cancel',
              cssClass: 'secondary',
              id: 'cancel-button',
              handler: () => {
            
              }
            }, {
              text: 'Si',
              id: 'confirm-button',
              handler: () => {
               this.removeProdDetail(i);
              }
            }
          ]
        });

        await alert.present();
  }
  removeProdDetail(i){ 
    this.prodDetails.splice(i,1); 
  }

  saveNewDetails(i){
    if(this.newProdDetails[i].amount == ''){
      this.service.presentToast('Favor de añadir una nueva cantidad');
      return;
    } else 
    if(this.newProdDetails[i].price == ''){
      this.service.presentToast('Favor de añadir un nuevo precio');
      return;
    } else {
     this.prodDetails.push(this.newProdDetails[i])
     this.newProdDetails.splice(i,1);
    }
  
  }

  removeFlavor(i){
    this.prodFlavors.splice(i,1);
    console.log(this.prodFlavors)
  }

  updateIngredients(value, i){
    console.log(this.prodIngredients); 
    this.prodIngredients.splice(i,1,value);
    console.log(this.prodIngredients);
    this.service.presentToast('Ingrediente actualizado')
  }

  addInputIngredients(){
    this.newIngredients.length += 1;
  }

  async removeNewIngredient(i){
   this.newIngredients.splice(i,1);
  }

  addIngredient(value){
     if(!value){
      this.service.presentToast('Favor proveer un nombre para el Ingrediente');
      return;
    }
    this.prodIngredients.push(value);
    this.newIngredients.length -= 1;
    this.service.presentToast('Ingrediente nuevo añadido');
    
  }

  removeIngredient(i){
    this.prodIngredients.splice(i,1)
    console.log(this.prodIngredients)
  }

async alertRemovalIng(i){
    const alert = await this.alertCtrl.create({
          cssClass: 'alertClass',
          header: 'Remover ' + this.prodIngredients[i] + '?',
          buttons: [
            {
              text: 'Cancel',
              role: 'cancel',
              cssClass: 'secondary',
              id: 'cancel-button',
              handler: () => {
            
              }
            }, {
              text: 'Si',
              id: 'confirm-button',
              handler: () => {
               this.removeIngredient(i);
              }
            }
          ]
        });

        await alert.present();
  }

  async alertRemovalFlav(i){
    const alert = await this.alertCtrl.create({
          cssClass: 'alertClass',
          header: 'Remover ' + this.prodFlavors[i] + '?', 
          buttons: [
            {
              text: 'Cancel',
              role: 'cancel',
              cssClass: 'secondary',
              id: 'cancel-button',
              handler: () => {
               
              }
            }, {
              text: 'Si',
              id: 'confirm-button',
              handler: () => {
                this.removeFlavor(i)
              }
            }
          ]
        });

        await alert.present();
  }

  

}
