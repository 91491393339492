<ion-header>
  <ion-toolbar> 
    <ion-title>{{prodName}}</ion-title>
    <ion-button fill="clear" color="dark" slot="end" (click)="closeModal()">Cerrar</ion-button>
  </ion-toolbar>
</ion-header>

<ion-content>

    <ion-row>
        <ion-col style="text-align: center;">
            <ion-img class="prodImg" src="{{prodImg?.url()}}"></ion-img>
            <ion-text style="font-size:10px">Tamaño Ideal: 1080x1080px</ion-text>
            <ion-button size="small" shape="round" expand="block" color="danger" (click)="initUpload()">
                Cambiar Imagen (JPG)
                <ion-spinner *ngIf="uploading" slot="end"></ion-spinner>
            </ion-button>
            <input type="file" accept="image/*" (change)="picChange($event)" id="adminProdModalPic">
        </ion-col>
    </ion-row>

    <ion-item lines="none">
        <ion-label position="stacked">Nombre</ion-label>
        <ion-input [(ngModel)]="prodName"></ion-input>
    </ion-item>
    <ion-item lines="none">
        <ion-label position="stacked">Descripción</ion-label>
        <ion-textarea [(ngModel)]="prodDesc" rows="2"></ion-textarea>
    </ion-item>

    <ion-row style="justify-content: left">

        <ion-col size="6">
            <ion-text style="font-size: 25px;">Modificadores</ion-text>

            <ion-select #M placeholder="Añadir Modificador" interface="popover" (ionChange)="pushNewMod(M.value)">
                <ion-select-option *ngFor="let mod of modifiers; let i = index" value="{{i}}">{{mod.get('name')}}</ion-select-option>
            </ion-select>
            <ion-list>
                <ion-item *ngFor="let modifier of prodAllowMods; let i = index" lines="none">
                    <ion-label>{{modifier.name}}</ion-label>
                    <ion-button color="danger" fill="clear" (click)="removeModifier(i)">
                        <ion-icon name="close-circle" color="danger" slot="icon-only" ></ion-icon>
                    </ion-button>
                </ion-item>
            </ion-list>
        </ion-col>
        <ion-col size="6">
            <ion-text style="font-size: 25px;">Extras</ion-text> 
            <ion-select #X placeholder="Añadir Extras" interface="popover" (ionChange)="pushNewXtra(X.value)" >
                <ion-select-option *ngFor="let mod of modifiers; let i = index" value="{{i}}">{{mod.get('name')}}</ion-select-option>
            </ion-select>
            <ion-list>
                <ion-item *ngFor="let xtras of prodAllowExtras; let i = index" lines="none">
                    <ion-label>{{xtras.name}}</ion-label>
                    <ion-button color="danger" fill="clear" (click)="removeExtra(i)">
                        <ion-icon name="close-circle" color="danger" slot="icon-only" ></ion-icon>
                    </ion-button>
                </ion-item>
            </ion-list>
        </ion-col>
    </ion-row>
    <ion-row>
        <ion-col>
            <ion-item lines="none">
                <ion-label position="stacked">{{prodIsActive ? 'Disponible' : 'No Disponible'}}</ion-label>
                <ion-toggle [(ngModel)]="prodIsActive" color="tertiary" mode="ios"></ion-toggle>
            </ion-item>
            <ion-item lines="none">
                <ion-label position="stacked">Producto se va a distribuir?</ion-label>
                <ion-toggle [(ngModel)]="prodIsVendorAllowed" color="tertiary" mode="ios"></ion-toggle>
            </ion-item>
            <ion-item lines="none">
                <ion-label position="stacked">Categoria</ion-label>
                <ion-text style="font-size: 12px; text-decoration: underline">Corriente: {{prodCategoryName}}</ion-text>
                <ion-select #C (ionChange)="changeProdCat(C.value)" interface="alert">
                    <ion-select-option *ngFor="let category of categories; let i = index" style="font-family: 'frekdoka'" value="{{category.id}}">{{category.get('name')}}</ion-select-option>
                </ion-select>
            </ion-item>
        </ion-col>
    </ion-row>
 

    <ion-row style="border-bottom: 1px solid lightgrey">
        <ion-col size="12">
            <ion-text>Opciones</ion-text>
        </ion-col>

        <ion-col size="12" *ngIf="prodDetails.length != 0">
            <ion-item lines="none" *ngFor="let detail of prodDetails; let i = index">
                <ion-label>Cantidad</ion-label>
                <ion-input [(ngModel)]="detail.amount"></ion-input>
                <ion-label>Precio</ion-label>
                <ion-input [(ngModel)]="detail.price"></ion-input>
                <ion-icon name="close-circle" color="danger" (click)="alertRemoveProdDetail(i)" slot="end" *ngIf="i != 0"></ion-icon>
            </ion-item>
        </ion-col>

        <ion-col size="12">
            <ion-item lines="none" *ngFor="let newDetails of newProdDetails; let i = index">
                <ion-icon name="close-circle" color="danger" (click)="removeNewDetail(i)" slot="end"></ion-icon>
                <ion-label>Cantidad</ion-label>
                <ion-input [(ngModel)]="newDetails.amount"></ion-input>
                <ion-label>Precio</ion-label>
                <ion-input [(ngModel)]="newDetails.price"></ion-input>
                <ion-icon name="save" (click)="saveNewDetails(i)" color="success"></ion-icon>
            </ion-item>
            <ion-button fill="clear" color="dark" (click)="addNewDetails()">
                Añadir Opción
                <ion-icon name="add-circle" color="success" slot="start"></ion-icon>
            </ion-button>
        </ion-col>
    </ion-row>

    <ion-row *ngIf="prodFlavors.length != 0">
        <ion-col>
            <ion-label position="stacked">Sabores</ion-label>
            <ion-item lines="none" *ngFor="let flavor of prodFlavors; let i = index">
                <ion-input autocapitalize="words" value="{{flavor}}" #Fl (ionChange)="updateFlavors(Fl.value, i)" debounce="2000"></ion-input>
                <ion-icon name="close-circle" color="danger" (click)="alertRemovalFlav(i)" slot="end"></ion-icon>
            </ion-item>
        </ion-col>
    </ion-row>

    <ion-row style="border-bottom: 1px solid lightgrey">
        <ion-item lines="none" *ngFor="let newFlavor of newFlavors; let i = index">
            <ion-input autocapitalize="words" #nF debounce="1000"></ion-input>
            <ion-icon name="close-circle" color="danger" (click)="removeNewFlavor(i)" slot="end"></ion-icon>
            <ion-icon name="save" (click)="addFlavor(nF.value)" color="success" slot="end"></ion-icon>
        </ion-item>
        <ion-button fill="clear" color="dark" (click)="addInputFlavor()">
            Sabores
            <ion-icon name="add-circle" color="success" slot="start"></ion-icon>
        </ion-button>
    </ion-row>

    <!--ion-row *ngIf="prodIngredients.length != 0">
        <ion-col>
            <ion-label position="stacked">Incluye</ion-label>
            <ion-item lines="none" *ngFor="let ing of prodIngredients; let i = index">
                <ion-input autocapitalize="words" value="{{ing}}" #In (ionChange)="updateIngredients(In.value, i)" debounce="2000"></ion-input>
                <ion-icon name="close-circle" color="danger" slot="end" (click)="alertRemovalIng(i)"></ion-icon>
            </ion-item>
        </ion-col>
    </ion-row>

    <ion-row>
        <ion-item lines="none" *ngFor="let newIngred of newIngredients; let i = index">
            <ion-input autocapitalize="words" #nF debounce="1000"></ion-input>
            <ion-icon name="close-circle" color="danger" (click)="removeNewIngredient(i)" slot="end"></ion-icon>
            <ion-icon name="save" (click)="addIngredient(nF.value)" color="success" slot="end"></ion-icon>
        </ion-item>
        <ion-button fill="clear" color="dark" (click)="addInputIngredients()">
            Añadir Ingrediente
            <ion-icon name="add-circle" color="success" slot="start"></ion-icon>
        </ion-button>
    </ion-row-->


</ion-content>

<ion-footer>
  <ion-row style="justify-content: center">
  <ion-col size="6">
    <ion-button expand="block"   color="tertiary" (click)="saveProduct()">Guardar
      <ion-spinner *ngIf="uploading" slot="end"></ion-spinner>
    </ion-button>
  </ion-col>
  <!--ion-col size="auto">
    <ion-button expand="block" fill="clear" size="small" color="danger" (click)="confirmProdRemoval()">Remover Producto
      <ion-spinner *ngIf="deleting" slot="end"></ion-spinner>
    </ion-button>
  </ion-col-->
</ion-row>
</ion-footer>
