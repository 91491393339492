import { Component, OnInit, ChangeDetectorRef, ViewChild } from '@angular/core';
import { ModalController, AnimationController,Animation, AlertController  } from '@ionic/angular';
import Parse from 'parse';
import { CookieService } from '../cookie.service'; 
import { Router } from '@angular/router';
import { IonAccordionGroup } from '@ionic/angular';

@Component({
  selector: 'app-product',
  templateUrl: './product.page.html',
  styleUrls: ['./product.page.scss'],
})
export class ProductPage implements OnInit {  
     
    product: any; // this.service.selectedProd;
    regSyrups: any = [];
    premSyrups: any = [];
    regToppings: any = [];
    premToppings: any = [];
    gelados: any = [];

    options: any = [];
    optionsData: any = [];
    cookieCups: any = [];

    sSCookieCup1: any = [];
    sSCookieCup2: any = [];
    sSCookieCup3: any = [];

    showCookieCups: boolean = false;
    allMods: any = [];
    heartType: string;
    heartEl: any;
    user: any;
    favorites: any = [];
  //  modifier: any = [];
    popoverData: any = [];
    premiumData: any = [];
    regularData: any = [];

    loading: boolean = false;
    showOptions: boolean = true;

    cart: any = [];
    orderQty: number = 0;
    minQuantity: number = 0; 
    sixChecked: boolean;
    twelveChecked: boolean; 
    baseOrder: any = [];

    cookieCupsOrder: any = [];
    addedOption: boolean = false;
    prodPrice: any;
    prevOption: any;
    galaxyCookies: any = [];
    galaxyCookieOrder: any = [];
    twinkleStarOrder: any = [];
    galaxyPlusCookies: any = [];
    galaxyPlusCookieOrder: any = [];

    prodFlavors: any = [];
    productDetails: any = [];
    prodModifiers: any = [];
    modifiers: any = [];

    iceCreamOptions1: any = [];
    iceCreamOptions2: any = [];
    iceCreamOptions3: any = [];
    syrupOptions: any = [];
    toppingOptions: any = [];
     
    extrasAdded: any = [];
    allowedExtras: any = [];

    storeIsOpen: boolean;

    prodQuantity: number = 1;
  constructor(
    private modalCtrl: ModalController,
    public service: CookieService,
    private alertCtrl: AlertController,
    private animationCtrl: AnimationController,
    private router: Router,
    private cd: ChangeDetectorRef
  ) { }

  ngOnInit() { 
      this.heartEl = document.getElementById('heart'); 
      this.getUser(); 
      this.getProduct();
      this.getStoreHours();
  }

  async getUser(){
    this.user = await this.service.getUser();
    this.cart = this.user?.get('cart');
  } 

  ionViewWillEnter(){ 
  }

    async getProduct() {
        this.loading = true;
        let query = new Parse.Query('Products');
        query.include('allowModifiers');
        //query.equalTo('objectId', this.service.selectedProd.id);
        var products = await query.find(); 
        this.loading = false;
         
       // this.getOptions();

        for (let i = 0; i < products.length; i++) {
            if (products[i].id == this.service.selectedProdId) {
                this.product = products[i]; 
                break;
            }
        }
        console.log(this.product)
        await this.getModifiers();
       // console.log(this.product);
        for (let i = 0; i < this.product.get('flavors').length; i++) {
            this.prodFlavors.push({
                flavor: this.product.get('flavors')[i],
                isChecked: false
            })
        }
        //if (this.prodFlavors.length == 0) {
        this.prodPrice = parseFloat(this.product.get('details')[0].price).toFixed(2); 
       // }
       this.productDetails = this.product.get('details');
       console.log(this.productDetails);

        for (let i = 0; i < this.product.get('allowModifiers').length; i++) {
            for (let k = 0; k < this.modifiers.length; k++) {
            
                if (this.product.get('allowModifiers')[i].pointer.id == this.modifiers[k].id) {
                     
                        this.prodModifiers[i] = {
                            name: this.product.get('allowModifiers')[i].name,
                            options: this.modifiers[k].get('data')
                        }
                                    
                } 
            }
        }
        //console.log(this.prodModifiers)

        for (let i = 0; i < this.product.get('allowExtras').length; i++) {
            for (let k = 0; k < this.modifiers.length; k++) {

                if (this.product.get('allowExtras')[i].pointer.id == this.modifiers[k].id) {

                    this.allowedExtras[i] = {
                        name: this.product.get('allowExtras')[i].name,
                        options: this.modifiers[k].get('data')
                    }

                }
            }
        }
    //    console.log(this.prodModifiers) 
        if (this.user) {
            this.getFavorites();
        }

        
    }

    setProductPrice(i:number){
        this.prodPrice = parseFloat(this.productDetails[i].price).toFixed(2);
        if(this.extrasAdded.length !== 0){
            let price = parseFloat(this.prodPrice);
            //console.log(this.extrasAdded, this.prodPrice)
            for(let k=0; k < this.extrasAdded.length; k++){
                price += this.extrasAdded[k].price;
                //let newPrice = (parseFloat(this.prodPrice) + option.price).toFixed(2) 
            }
            this.prodPrice = price.toFixed(2);
        }
    }

    async getStoreHours() {
        let query = new Parse.Query('Controls');
        let controls = await query.first();

        var storeHours = controls.get('hours');

        let now = new Date();
        let dayCode = now.getDay();
        let currentHour = now.getHours();
        let currentMinutes = now.getMinutes();

        for (let i = 0; i < storeHours.length; i++) {

            if (dayCode == storeHours[i].dayCode) {

                var storeOpenTime = parseFloat(storeHours[i].openMilHour.toString() + (storeHours[i].openMilMin.toString() == '0' ? '00' : storeHours[i].openMilMin.toString()))
                var currentTime = parseFloat(currentHour.toString() + (currentMinutes < 10 ? ('0'+currentMinutes.toString()) : currentMinutes.toString()))
                var storeCloseTime = parseFloat(storeHours[i].closeMilHour.toString() + (storeHours[i].closeMilMin.toString() == '0' ? '00' : storeHours[i].closeMilMin.toString()))

                if (currentTime >= storeOpenTime && currentTime <= storeCloseTime) {
                    this.storeIsOpen = true;
                } else {
                    this.storeIsOpen = false;
                }
                break;
            }
        }
    }
     

  async getFavorites(){
    this.favorites = this.user.get('favorites'); 
    this.favorites.length == 0 ? this.heartType = 'heart-outline' : null;
    this.isProductFavorite();

  }

  closeModal(){
    this.modalCtrl.dismiss(true);
  }

    async getModifiers() { 
        let query = new Parse.Query('Modifiers');
      //  query.equalTo('objectId', this.product.get('allowModifiers')[i].pointer.id)
        this.modifiers = await query.find(); 
     //   this.popoverData = this.modifier.get('data');
      
        this.premiumData.length = 0;
        this.regularData.length = 0;

        for (let k = 0; k < this.popoverData.length; k++) {
            if (this.popoverData[k].isPremium) {
                this.premiumData.push(this.popoverData[k]);
            } else   {
                this.regularData.push(this.popoverData[k]);
            }
        }
    }

    async getOptions() {
        let query = new Parse.Query('Modifiers');
        var modifiers = await query.find();
        var options = this.product.get('allowModifiers');
        var extras = this.product.get('allowExtras');

        // console.log(modifiers);
/*
        for (let k = 0; k < modifiers.length; k++) {

            if (modifiers[k].allow) {
                for (let i = 0; i < options.length; i++) {
                    if (modifiers[k].pointer.id == options[i].id) {

                        this.options.push({
                            name: options[i].get('name'),
                            data: []
                        });
                        var data;

                        for (let j = 0; j < options[i].get('data').length; j++) {
                            if (options[i].get('data')[j].available) {
                                data = {
                                    name: options[i].get('data')[j].name,
                                    //available: options[i].get('data')[j].available,
                                    isPremium: options[i].get('data')[j].isPremium,
                                    price: options[i].get('data')[j].price,
                                    isChecked: false
                                }
                                this.options[k].data.push(data);
                            }
                        }
                    }

                }
            }
        }
         
        for (let k = 0; k < extras.length; k++) {
           
            if (options[k].available) {

                for (let l = 0; l < extras.length; l++) { 
                    console.log(extras[l].pointer.id == options[k].id)
                    if (extras[l].pointer.id == options[k].id) {
                        this.allowedExtras.push({
                            name: options[k].name,
                            data: []
                        })
                        console.log(this.allowedExtras)
                        var data;

                        for (let j = 0; j < options[k].get('data').length; j++) {
                            if (options[k].get('data')[j].available) {
                                data = {
                                    name: options[k].get('data')[j].name,
                                    //available: options[i].get('data')[j].available,
                                    isPremium: options[k].get('data')[j].isPremium,
                                    price: options[k].get('data')[j].price,
                                    isChecked: false
                                }
                                this.allowedExtras[k].data.push(data);
                            }
                        }
                    }
                }
            }*/

            for (let i = 0; i < this.options.length; i++) {
                if (this.options[i].name == 'Helados') {
                    for (let k = 0; k < this.options[i].data.length; k++) {
                        this.iceCreamOptions1.push({
                            name: this.options[i].data[k].name,
                            isPremium: this.options[i].data[k].isPremium,
                            price: this.options[i].data[k].price,
                            isChecked: false
                        })
                        this.iceCreamOptions2.push({
                            name: this.options[i].data[k].name,
                            isPremium: this.options[i].data[k].isPremium,
                            price: this.options[i].data[k].price,
                            isChecked: false
                        })
                        this.iceCreamOptions3.push({
                            name: this.options[i].data[k].name,
                            isPremium: this.options[i].data[k].isPremium,
                            price: this.options[i].data[k].price,
                            isChecked: false
                        })
                    }

                }
                if (this.options[i].name == 'Toppings') {
                    this.toppingOptions = this.options[i].data;
                }
                if (this.options[i].name == 'Syrups') {
                    this.syrupOptions = this.options[i].data;
                }
            }


       // }
    }
 
  async addToFavorites(){
    if(!this.user){
      this.presentAcctAlert(); 
      return;
    } 

    var found: boolean = false;
    
    if(this.favorites.length == 0){
     this.user.add('favorites', this.product);
     await this.user.save();
      this.heartEl.style.color = "red";
        this.heartType = 'heart';
          this.service.presentToast('Se ha añadido a sus favoritos');
        this.getFavorites();
      return;

    } else {
        for(let i=0; i < this.favorites.length; i++){
          if(this.favorites[i].id == this.product.id){ 
              this.favorites.splice(i,1);
                this.heartEl.style.color = 'black';
                  this.heartType = 'heart-outline';
                     this.user.set('favorites', this.favorites);
                     await this.user.save();
                this.service.presentToast('Se removió de sus favoritos');
              found = true;
            break;
          }
        }
      } 

    if(!found){ 
        this.user.add('favorites', this.product);
        await this.user.save();
          this.heartEl.style.color = "red";
            this.heartType = 'heart';
          this.service.presentToast('Se ha añadido a sus favoritos');
        this.getFavorites();
    }  
  }

  async isProductFavorite(){ 
    this.favorites = this.user.get('favorites'); 
      if(this.favorites.length == 0){ 
      } else {
          for(let i=0; i < this.favorites.length; i++){
            if(this.favorites[i].id == this.product.id){ 
                this.heartEl.style.color = "red";
                this.heartType = 'heart'; 
                break;
            } else {
              this.heartType = 'heart-outline';
            }  
        }
      }   
  }

  async presentAcctAlert() {
    const alert = await this.alertCtrl.create({
      cssClass: 'alertClass',
      header: 'Aún no has creado una cuenta',
      message: '¡Crea una cuenta para que puedas guardar tus favoritos!',
      buttons: [
        {
          text: 'No, Gracias',
          role: 'cancel',
          cssClass: 'secondary', 
          handler: () => { 
            return;
          }
        }, {
          text: '¡Vamos!',
          id: 'confirm-button',
          cssClass: 'Primary',
          handler: () => {
            this.modalCtrl.dismiss();
            this.router.navigate(['create-account']);
          }
        }
      ]
    });

    await alert.present();
    }

    setCookieQuantity(amount: number) {
        this.minQuantity = amount;
        let icon6 = document.getElementById('cookies6');
        let icon12 = document.getElementById('cookies12');

        if (amount == 6) {
            this.sixChecked = true;
            this.twelveChecked = false;
             
                icon6.setAttribute('name', 'checkmark-circle');
                icon6.setAttribute('color', 'primary');
                icon12.setAttribute('name', 'ellipse-outline');
                icon12.setAttribute('color', 'dark');
            this.prodPrice = 8;
            if (this.cookieCupsOrder.length > 6) {

                for (let i = 6; i < this.cookieCupsOrder.length; i++) {
                    for (let k = 0; k < this.cookieCups.length; k++) {
                        if (this.cookieCups[k].flavor == this.cookieCupsOrder[i].prod) {
                            this.cookieCups[k].isChecked = false;
                        }
                    }
                }

                this.cookieCupsOrder.splice(6, this.cookieCupsOrder.length - 6)
            }
        }

        if (amount == 12) {
            icon6.setAttribute('name', 'ellipse-outline');
            icon6.setAttribute('color', 'dark');
            icon12.setAttribute('name', 'checkmark-circle');
            icon12.setAttribute('color', 'primary');
            this.sixChecked = false;
            this.twelveChecked = true;
            this.prodPrice = 15;
        }

    } 
     
     

    addToCookieOrder(i) {
        if (!this.minQuantity) {
            this.service.presentToast('Favor de seleccionar la cantidad de la orden');
           // this.cookieCups[i].isChecked = false;
            this.cd.detectChanges();
        } else
            if (!this.prodFlavors[i].isChecked) {
                this.prodFlavors[i].isChecked = true;
                this.cookieCupsOrder.push({
                    cookie: this.prodFlavors[i].flavor,
                    qty: 1
                })
               
                this.orderQty += 1; 
            }

            else { 
                for (let k = 0; k < this.cookieCupsOrder.length; k++) {
                    if (this.cookieCupsOrder[k].cookie == this.prodFlavors[i].flavor) {
                        this.prodFlavors[i].isChecked = false;
                        this.orderQty -= this.cookieCupsOrder[k].qty;
                        this.cookieCupsOrder.splice(k, 1);
                    }
                } 
            }
         

        var orderTotal = 0;

        for (let k = 0; k < this.cookieCupsOrder.length; k++) {
       
            orderTotal += this.cookieCupsOrder[k].qty
        }

        if (orderTotal > this.minQuantity) {
            this.service.presentToast('Total de cookie cups excede cantidad');
            this.prodFlavors[i].isChecked = false;
            this.cookieCupsOrder.splice(this.cookieCupsOrder.length - 1, 1)
        }   

       
       

    }

    updateCookieQty(cookie, qty) {
        var updatedQty = 0;
        var prevQty = 0;

        for (let i = 0; i < this.cookieCupsOrder.length; i++) {

            if (this.cookieCupsOrder[i].cookie == cookie) {
                prevQty = this.cookieCupsOrder[i].qty;
                this.cookieCupsOrder[i].qty = parseFloat(qty);
            }

            updatedQty += this.cookieCupsOrder[i].qty;
        } 

            if (updatedQty > this.minQuantity) {
                this.service.presentToast('Total de cookie cups excede cantidad');
                var ionSelect = document.getElementById(cookie); 
                ionSelect.setAttribute('value', prevQty.toString());
                for (let i = 0; i < this.cookieCupsOrder.length; i++) {
                    if (this.cookieCupsOrder[i].cookie == cookie) {
                        this.cookieCupsOrder[i].qty = prevQty;
                        updatedQty += this.cookieCupsOrder[i].qty;

                    }
                }
            }   
    }

    async checkCurrentCookieOrder() {
        console.log("Checking Cookies")
        var totalQty = 0;

        if (this.cookieCupsOrder.length == 0) {
            this.service.presentToast('Favor de completar su seleccion');
            return false;
        }

        for (let i = 0; i < this.cookieCupsOrder.length; i++) {
            totalQty += this.cookieCupsOrder[i].qty;
        }

        if (this.minQuantity == 6) { 

            if (totalQty < 6) {
                this.service.presentToast('Falta de escoger ' + (this.minQuantity - totalQty) + ' galleta(s)');
                 return false;
             }           
        }

        if (this.minQuantity == 12) {
            if (totalQty < 12) {
                this.service.presentToast('Falta de escoger ' + (this.minQuantity - totalQty) + ' galleta(s)');
                 return false;
             }
        }
        return true; 
    }

    async checkBaseOrder() {
        var passed = false;
        if (this.prodModifiers.length == 0) { 
            passed = true;
        } else
        if (this.baseOrder.length < this.prodModifiers.length) {
            this.service.presentToast('Favor de completar su seleccion');
        } else if (this.baseOrder.length == 0) { 
            this.service.presentToast('Favor de completar su seleccion'); 
        } else {
            passed = true;
        }
        return passed;
    }

    async checkGalaxyOrder() {
        
        var totalQty = 0;
        if (this.product.get('name') == 'Galaxy') {

            if (this.galaxyCookieOrder.length == 0) {
                this.service.presentToast('Favor de completar su seleccion');
                return false;
            }

            for (let i = 0; i < this.galaxyCookieOrder.length; i++) {
                totalQty += this.galaxyCookieOrder[i].qty;
            }

        } else
        if (this.product.get('name') == 'Twinkle Star') {

            if (this.twinkleStarOrder.length == 0) {
                this.service.presentToast('Favor de completar su seleccion');
                return false;
            }

            for (let i = 0; i < this.twinkleStarOrder.length; i++) {
                totalQty += this.twinkleStarOrder[i].qty;
            }
            //console.log(this.twinkleStarOrder)

        } else

        if (this.product.get('name') == 'Galaxy Plus') {

            if (this.galaxyPlusCookieOrder.length == 0) {
                this.service.presentToast('Favor de completar su seleccion');
                return false;
            } else

            if (this.baseOrder.length == 0) {
                this.service.presentToast('Favor Seleccionar un Topping');
                return false;
            }

            for (let i = 0; i < this.galaxyPlusCookieOrder.length; i++) {
                totalQty += this.galaxyPlusCookieOrder[i].qty;
            }

        }
        

        if (this.minQuantity == 1) {

            if (totalQty < 1) {
                this.service.presentToast('Falta de escoger ' + (this.minQuantity - totalQty) + ' galleta(s)');
                return false;
            }
        }

        if (this.minQuantity == 2) {
            if (totalQty < 2) {
                this.service.presentToast('Falta de escoger ' + (this.minQuantity - totalQty) + ' galleta(s)');
                return false;
            }
        }

        if (this.minQuantity == 6) {
            if (totalQty < 6) {
                this.service.presentToast('Falta de escoger ' + (this.minQuantity - totalQty) + ' galleta(s)');
                return false;
            }
        }

        if (this.minQuantity == 8) {
            if (totalQty < 8) {
                this.service.presentToast('Falta de escoger ' + (this.minQuantity - totalQty) + ' galleta(s)');
                return false;
            }
        }

        return true; 
    }
     

    addCookieFlavor(i) {
        this.addedOption = true; 
        for (let k = 0; k < this.cookieCups.length; k++) {
            if (this.cookieCups[k].isChecked) {
                this.cookieCups[k].isChecked = false;
            }
        }
        this.cookieCups[i].isChecked = true;
        this.baseOrder.cookieCup = this.cookieCups[i].flavor;  
    }

    removeCookieFlavor(i) {
        this.cookieCups[i].isChecked = false;
        this.baseOrder.cookieCup = ''; 

    }

    addOption(k, i, type) {
        const accordionGroup = document.querySelector('ion-accordion-group');
        

      //  var newPrice = this.prodPrice;
        //Close the accordion
        if (accordionGroup.value === type) {
            accordionGroup.value = null;
        } else {
            accordionGroup.value = type;
        }; 

        var newPrice = this.prodPrice;

        if (this.baseOrder.length == 0) {
            this.baseOrder.push({
                type: type,
                flavor: this.prodModifiers[k].options[i].name,
                isPremium: this.prodModifiers[k].options[i].isPremium,
                price: this.prodModifiers[k].options[i].price
            });
            if(this.prodModifiers[k].options[i].isPremium){
                newPrice = (parseFloat(this.prodPrice) + this.prodModifiers[k].options[i].price).toFixed(2) 
            } 
        } else {
            var typeFound = false;
            for (let j = 0; j < this.baseOrder.length; j++) {
                if (this.baseOrder[j].type == type) {

                    if(!this.prodModifiers[k].options[i].isPremium){
                        if(this.baseOrder[j].isPremium){
                            newPrice = (parseFloat(this.prodPrice) - this.baseOrder[j].price).toFixed(2) 
                        } 
                    } else {
                        if(!this.baseOrder[j].isPremium){ 
                            newPrice = (parseFloat(this.prodPrice) + this.prodModifiers[k].options[i].price).toFixed(2) 
                        }
                    } 

                    this.baseOrder.splice(j, 1); 

                    this.baseOrder.push({
                        type: type,
                        flavor: this.prodModifiers[k].options[i].name,
                        isPremium: this.prodModifiers[k].options[i].isPremium,
                        price: this.prodModifiers[k].options[i].price
                    }); 

                   // if(this.prodModifiers[k].options[i].isPremium){
                   //     newPrice = (parseFloat(this.prodPrice) + this.prodModifiers[k].options[i].price).toFixed(2) 
                   // }
                     
                    //console.log('same type') 
                   
                   
                   
                    /*if (this.extrasAdded[i].flavors.includes(option.name)) {
                        this.extrasAdded[i].flavors.splice(this.extrasAdded[i].flavors.indexOf(option.name), 1)
                        newPrice = (parseFloat(this.prodPrice) - option.price).toFixed(2)
                        this.prodPrice = newPrice;
                    } else {
                        this.extrasAdded[i].flavors.push(option.name);
                        newPrice = (parseFloat(this.prodPrice) + option.price).toFixed(2)
                        this.prodPrice = newPrice;
                    }*/
                   

                    typeFound = true;
                }
            }
            console.log(typeFound)
            if (!typeFound) {
                this.baseOrder.push({
                    type: type,
                    flavor: this.prodModifiers[k].options[i].name,
                    isPremium: this.prodModifiers[k].options[i].isPremium,
                    price: this.prodModifiers[k].options[i].price
                }); 
                if(this.prodModifiers[k].options[i].isPremium){
                    newPrice = (parseFloat(this.prodPrice) + this.prodModifiers[k].options[i].price).toFixed(2)
                } 
            } 
        }
        this.prodPrice = newPrice; 
        
      /*  this.addedOption = true;
        //Remove previous choice if the same
        for (let j = 0; j < this.baseOrder.length; j++) {
                if (this.baseOrder[j].type == type) {
                    this.baseOrder.splice(j, 1); 
                   // if(this.prevOption.isPremium){
                  //      newPrice = (parseFloat(this.prodPrice) - this.prevOption.price).toFixed(2) 
                  //  }
                }; 
        };
        //Add new choice or replace if the same
        this.baseOrder.push({
            type: type,
            flavor: this.prodModifiers[k].options[i].name
        });

        console.log(this.baseOrder) 
        console.log(this.prodModifiers[k].options[i].price);

        //Increase price if option is Premium
        if(this.prodModifiers[k].options[i].isPremium){
            newPrice = (parseFloat(this.prodPrice) + this.prodModifiers[k].options[i].price).toFixed(2)  
        }  
        if(type != this.prevType){
            if(this.prodModifiers[k].options[i].isPremium){
                newPrice = (parseFloat(this.prodPrice) + this.prodModifiers[k].options[i].price).toFixed(2) 
                this.prodPrice = newPrice;
            } else {
                this.prodPrice = parseFloat(this.product.get('details')[0].price).toFixed(2)
            }
        } 
        this.prodPrice = newPrice;
        this.prevOption = this.prodModifiers[k].options[i]
        console.log(this.prodPrice)*/
    }



    addExtras(type, option) { 
        //console.log(option);
        if (this.extrasAdded.length == 0) {
            this.extrasAdded.push({
                type: type,
                flavors: [option.name],
                price: option.price
            });
            var newPrice = (parseFloat(this.prodPrice) + option.price).toFixed(2) 
            this.prodPrice = newPrice;
            
        } else {
            var typeFound = false;
            for (let i = 0; i < this.extrasAdded.length; i++) {
                if (this.extrasAdded[i].type == type) {
                   // console.log('same type')
                    if (this.extrasAdded[i].flavors.includes(option.name)) {
                        this.extrasAdded[i].flavors.splice(this.extrasAdded[i].flavors.indexOf(option.name), 1)
                        newPrice = (parseFloat(this.prodPrice) - option.price).toFixed(2)
                        this.prodPrice = newPrice;
                        console.log(this.extrasAdded)
                    } else {
                        this.extrasAdded[i].flavors.push(option.name);
                        newPrice = (parseFloat(this.prodPrice) + option.price).toFixed(2)
                        this.prodPrice = newPrice;
                        console.log(this.extrasAdded)
                    }
                    typeFound = true;
                }
            }
            //console.log(typeFound)
            if (!typeFound) {
                const payload = {
                    type: type,
                    flavors: [option.name],
                    price: option.price
                }

                this.extrasAdded.push(
                    payload
                ); 

                newPrice = (parseFloat(this.prodPrice) + option.price).toFixed(2)
                this.prodPrice = newPrice;
            }
        }
       
    }
     



    removeOption(k, i, type) { 

        if (this.product.get('name') == 'Spaceship') {

            for (let j = 0; j < this.baseOrder.length; j++) {
                if (this.baseOrder[j].type == type) {
                    this.baseOrder.splice(j, 1);
                }
            }

            for (let j = 0; j < this.prodModifiers[k].options.length; j++) {
                if (this.prodModifiers[k].options[j].isChecked) {
                    this.prodModifiers[k].options[j].isChecked = false;
                }
            }
        }
 
    }

    setTwinkleQuantity(amount: number, price: number){  
        this.minQuantity = amount;
        //let icon1 = document.getElementById('twinkle1');
        let icon6 = document.getElementById('twinkle8');

        if (amount == 1) {
        

            //icon1.setAttribute('name', 'checkmark-circle');
            //icon1.setAttribute('color', 'primary'); 
            icon6.setAttribute('name', 'ellipse-outline');
            icon6.setAttribute('color', 'dark'); 

            if (this.twinkleStarOrder.length > 1) {

                for (let i = 1; i < this.twinkleStarOrder.length; i++) {
                    for (let k = 0; k < this.prodFlavors.length; k++) {
                        if (this.twinkleStarOrder[i].cookie == this.prodFlavors[k].flavor) {
                            this.prodFlavors[k].isChecked = false;
                        }
                    }
                }
                this.twinkleStarOrder.splice(1, this.twinkleStarOrder.length - 1)

            }
        }

       /* if (amount == 2) {
            icon1.setAttribute('name', 'ellipse-outline');
            icon1.setAttribute('color', 'dark'); 
            icon6.setAttribute('name', 'ellipse-outline');
            icon6.setAttribute('color', 'dark'); 

            if (this.twinkleStarOrder.length > 2) {

                for (let i = 2; i < this.twinkleStarOrder.length; i++) {
                    for (let k = 0; k < this.prodFlavors.length; k++) {
                        if (this.twinkleStarOrder[i].cookie == this.prodFlavors[k].flavor) {
                            this.prodFlavors[k].isChecked = false;
                        }
                    }
                }
                this.twinkleStarOrder.splice(2, this.twinkleStarOrder.length - 2)

            }
        }*/

        if (amount == 8) {
            //icon1.setAttribute('name', 'ellipse-outline');
            //icon1.setAttribute('color', 'dark'); 
            icon6.setAttribute('name', 'checkmark-circle');
            icon6.setAttribute('color', 'primary'); 
        } 
        this.prodPrice = price
    }

    setGalaxyQuantity(amount: number, price: number) {
        
        this.minQuantity = amount;
        let icon1 = document.getElementById('cookies1');
        let icon2 = document.getElementById('cookies2');
        let icon6 = document.getElementById('cookies6');

        if (amount == 1) {
        

            icon1.setAttribute('name', 'checkmark-circle');
            icon1.setAttribute('color', 'primary');
            icon2.setAttribute('name', 'ellipse-outline');
            icon2.setAttribute('color', 'dark');
            icon6.setAttribute('name', 'ellipse-outline');
            icon6.setAttribute('color', 'dark'); 

            if (this.galaxyCookieOrder.length > 1) {

                for (let i = 1; i < this.galaxyCookieOrder.length; i++) {
                    for (let k = 0; k < this.prodFlavors.length; k++) {
                        if (this.galaxyCookieOrder[i].cookie == this.prodFlavors[k].flavor) {
                            this.prodFlavors[k].isChecked = false;
                        }
                    }
                }
                this.galaxyCookieOrder.splice(1, this.galaxyCookieOrder.length - 1)

            }
        }

        if (amount == 2) {
            icon1.setAttribute('name', 'ellipse-outline');
            icon1.setAttribute('color', 'dark');
            icon2.setAttribute('name', 'checkmark-circle');
            icon2.setAttribute('color', 'primary');
            icon6.setAttribute('name', 'ellipse-outline');
            icon6.setAttribute('color', 'dark'); 

            if (this.galaxyCookieOrder.length > 2) {

                for (let i = 2; i < this.galaxyCookieOrder.length; i++) {
                    for (let k = 0; k < this.prodFlavors.length; k++) {
                        if (this.galaxyCookieOrder[i].cookie == this.prodFlavors[k].flavor) {
                            this.prodFlavors[k].isChecked = false;
                        }
                    }
                }
                this.galaxyCookieOrder.splice(2, this.galaxyCookieOrder.length - 2)

            }
        }

        if (amount == 6) {
            icon1.setAttribute('name', 'ellipse-outline');
            icon1.setAttribute('color', 'dark');
            icon2.setAttribute('name', 'ellipse-outline');
            icon2.setAttribute('color', 'dark');
            icon6.setAttribute('name', 'checkmark-circle');
            icon6.setAttribute('color', 'primary');
 

        } 
        this.prodPrice = price
    } 

    setGalaxyPlusQuantity(amount: number, price: number) {

        this.minQuantity = amount;
        let icon1 = document.getElementById('cookies1');
        let icon2 = document.getElementById('cookies2');
        let icon6 = document.getElementById('cookies6');

        if (amount == 1) {


            icon1.setAttribute('name', 'checkmark-circle');
            icon1.setAttribute('color', 'primary');
            icon2.setAttribute('name', 'ellipse-outline');
            icon2.setAttribute('color', 'dark');
            icon6.setAttribute('name', 'ellipse-outline');
            icon6.setAttribute('color', 'dark');

            if (this.galaxyPlusCookieOrder.length > 1) {

                for (let i = 1; i < this.galaxyPlusCookieOrder.length; i++) {
                    for (let k = 0; k < this.prodFlavors.length; k++) {
                        if (this.galaxyPlusCookieOrder[i].cookie == this.prodFlavors[k].flavor) {
                            this.prodFlavors[k].isChecked = false;
                        }
                    }
                }
                this.galaxyPlusCookieOrder.splice(1, this.galaxyPlusCookieOrder.length - 1)

            }
        }

        if (amount == 2) {
            icon1.setAttribute('name', 'ellipse-outline');
            icon1.setAttribute('color', 'dark');
            icon2.setAttribute('name', 'checkmark-circle');
            icon2.setAttribute('color', 'primary');
            icon6.setAttribute('name', 'ellipse-outline');
            icon6.setAttribute('color', 'dark');

            if (this.galaxyPlusCookieOrder.length > 2) {

                for (let i = 2; i < this.galaxyPlusCookieOrder.length; i++) {
                    for (let k = 0; k < this.prodFlavors.length; k++) {
                        if (this.galaxyPlusCookieOrder[i].cookie == this.prodFlavors[k].flavor) {
                            this.prodFlavors[k].isChecked = false;
                        }
                    }
                }
                this.galaxyPlusCookieOrder.splice(2, this.galaxyPlusCookieOrder.length - 2)

            }
        }

        if (amount == 6) {
            icon1.setAttribute('name', 'ellipse-outline');
            icon1.setAttribute('color', 'dark');
            icon2.setAttribute('name', 'ellipse-outline');
            icon2.setAttribute('color', 'dark');
            icon6.setAttribute('name', 'checkmark-circle');
            icon6.setAttribute('color', 'primary');


        }
        this.prodPrice = price
    } 

    addTwinkleToOrder(i) {
        if (!this.minQuantity) {
            this.service.presentToast('Favor de seleccionar la cantidad de la orden');
            // this.cookieCups[i].isChecked = false;
            this.cd.detectChanges();
            return;
        }
 

        if (!this.prodFlavors[i].isChecked) {

            this.prodFlavors[i].isChecked = true;
                this.twinkleStarOrder.push({
                    cookie: this.prodFlavors[i].flavor,
                    qty: 1
                });

                this.orderQty += 1;
            }

            else {

                for (let k = 0; k < this.twinkleStarOrder.length; k++) {
                    if (this.twinkleStarOrder[k].cookie == this.prodFlavors[i].flavor) {
                        this.prodFlavors[i].isChecked = false;
                        this.orderQty -= this.twinkleStarOrder[k].qty;
                        this.twinkleStarOrder.splice(k, 1);
                    }
                }

            }

            var orderTotal = 0;

            for (let k = 0; k < this.twinkleStarOrder.length; k++) {

                orderTotal += this.twinkleStarOrder[k].qty
            }

            if (orderTotal > this.minQuantity) {
                this.service.presentToast('Total de galletas excede cantidad');
                this.prodFlavors[i].isChecked = false;
                this.twinkleStarOrder.splice(this.twinkleStarOrder.length - 1, 1)
        }

        ///console.log(this.twinkleStarOrder)
    }

    addGalaxyToOrder(i) {
        if (!this.minQuantity) {
            this.service.presentToast('Favor de seleccionar la cantidad de la orden');
            // this.cookieCups[i].isChecked = false;
            this.cd.detectChanges();
            return;
        }
 

        if (!this.prodFlavors[i].isChecked) {

            this.prodFlavors[i].isChecked = true;
                this.galaxyCookieOrder.push({
                    cookie: this.prodFlavors[i].flavor,
                    qty: 1
                });

                this.orderQty += 1;
            }

            else {

                for (let k = 0; k < this.galaxyCookieOrder.length; k++) {
                    if (this.galaxyCookieOrder[k].cookie == this.prodFlavors[i].flavor) {
                        this.prodFlavors[i].isChecked = false;
                        this.orderQty -= this.galaxyCookieOrder[k].qty;
                        this.galaxyCookieOrder.splice(k, 1);
                    }
                }

            }

            var orderTotal = 0;

            for (let k = 0; k < this.galaxyCookieOrder.length; k++) {

                orderTotal += this.galaxyCookieOrder[k].qty
            }

            if (orderTotal > this.minQuantity) {
                this.service.presentToast('Total de cookie cups excede cantidad');
                this.prodFlavors[i].isChecked = false;
                this.galaxyCookieOrder.splice(this.galaxyCookieOrder.length - 1, 1)
        }

        console.log(this.galaxyCookieOrder)
    }

    addGalaxyPlus(i) {
        if (!this.minQuantity) {
            this.service.presentToast('Favor de seleccionar la cantidad de la orden');
            // this.cookieCups[i].isChecked = false;
            this.cd.detectChanges();
            return;
        }

        if (!this.prodFlavors[i].isChecked) {
            this.prodFlavors[i].isChecked = true;

            this.galaxyPlusCookieOrder.push({
                cookie: this.prodFlavors[i].flavor,
                qty: 1
            })
            this.orderQty += 1;
        }

        else {
            for (let k = 0; k < this.galaxyPlusCookieOrder.length; k++) {
                if (this.galaxyPlusCookieOrder[k].cookie == this.prodFlavors[i].flavor) {
                    this.prodFlavors[i].isChecked = false;
                    this.orderQty -= this.galaxyPlusCookieOrder[k].qty;
                    this.galaxyPlusCookieOrder.splice(k, 1);
                }
            }
        }


        var orderTotal = 0;

        for (let k = 0; k < this.galaxyPlusCookieOrder.length; k++) {

            orderTotal += this.galaxyPlusCookieOrder[k].qty
        }

        if (orderTotal > this.minQuantity) {
            this.service.presentToast('Total de cookie cups excede cantidad');
            this.prodFlavors[i].isChecked = false;
            this.galaxyPlusCookieOrder.splice(this.galaxyCookieOrder.length - 1, 1)
        } 
        console.log(this.galaxyPlusCookieOrder)

    }

    updateTwinkleQty(cookie, qty) {
        var updatedQty = 0;
        var prevQty = 0;

        for (let i = 0; i < this.twinkleStarOrder.length; i++) {

            if (this.twinkleStarOrder[i].cookie == cookie) {
                prevQty = this.twinkleStarOrder[i].qty;
                this.twinkleStarOrder[i].qty = parseFloat(qty);
            }

            updatedQty += this.twinkleStarOrder[i].qty;
        }

        if (updatedQty > this.minQuantity) {
            this.service.presentToast('Total de galletas excede cantidad');
            var ionSelect = document.getElementById(cookie);
            ionSelect.setAttribute('value', prevQty.toString());
            for (let i = 0; i < this.twinkleStarOrder.length; i++) {
                if (this.twinkleStarOrder[i].cookie == cookie) {
                    this.twinkleStarOrder[i].qty = prevQty;
                    updatedQty += this.twinkleStarOrder[i].qty;

                }
            }
        }
       // console.log(this.twinkleStarOrder)
    }

    updateGalaxyQty(cookie, qty) {
        var updatedQty = 0;
        var prevQty = 0;

        for (let i = 0; i < this.galaxyCookieOrder.length; i++) {

            if (this.galaxyCookieOrder[i].cookie == cookie) {
                prevQty = this.galaxyCookieOrder[i].qty;
                this.galaxyCookieOrder[i].qty = parseFloat(qty);
            }

            updatedQty += this.galaxyCookieOrder[i].qty;
        }

        if (updatedQty > this.minQuantity) {
            this.service.presentToast('Total de cookie cups excede cantidad');
            var ionSelect = document.getElementById(cookie);
            ionSelect.setAttribute('value', prevQty.toString());
            for (let i = 0; i < this.galaxyCookieOrder.length; i++) {
                if (this.galaxyCookieOrder[i].cookie == cookie) {
                    this.galaxyCookieOrder[i].qty = prevQty;
                    updatedQty += this.galaxyCookieOrder[i].qty;

                }
            }
        }
    }

    updateGalaxyPlusQty(cookie, qty) {
        var updatedQty = 0;
        var prevQty = 0;

        for (let i = 0; i < this.galaxyPlusCookieOrder.length; i++) {

            if (this.galaxyPlusCookieOrder[i].cookie == cookie) {
                prevQty = this.galaxyPlusCookieOrder[i].qty;
                this.galaxyPlusCookieOrder[i].qty = parseFloat(qty);
            }

            updatedQty += this.galaxyPlusCookieOrder[i].qty;
        }

        if (updatedQty > this.minQuantity) {
            this.service.presentToast('Total de cookie cups excede cantidad');
            var ionSelect = document.getElementById(cookie);
            ionSelect.setAttribute('value', prevQty.toString());
            for (let i = 0; i < this.galaxyPlusCookieOrder.length; i++) {
                if (this.galaxyPlusCookieOrder[i].cookie == cookie) {
                    this.galaxyPlusCookieOrder[i].qty = prevQty;
                    updatedQty += this.galaxyPlusCookieOrder[i].qty;

                }
            }
        }
    }

    addSpaceshipCookie(i: number, spot: number, flavor: string) {

        this.baseOrder = {
            cookieCup1: '',
            scoop1: '',
            cookieCup2: '',
            scoop2: '',
            cookieCup3: '',
            scoop3: '',
            topping: '',
            syrup: '',
        }

        this.addedOption = true;
        if (spot == 0) {
            this.baseOrder.cookieCup1 = flavor;
            for (let k = 0; k < this.sSCookieCup1.length; k++) {
                if (this.sSCookieCup1[k].isChecked) {
                    this.sSCookieCup1[k].isChecked = false;
                }
            }
            this.sSCookieCup1[i].isChecked = true;
            console.log(this.sSCookieCup1[i])
        } else
            if (spot == 1) {
                this.baseOrder.cookieCup2 = flavor;
                for (let k = 0; k < this.sSCookieCup2.length; k++) {
                    if (this.sSCookieCup2[k].isChecked) {
                        this.sSCookieCup2[k].isChecked = false;
                    }
                }
                this.sSCookieCup2[i].isChecked = true;
                console.log(this.sSCookieCup2[i])

            } else
                if (spot == 2) {
                    this.baseOrder.cookieCup3 = flavor;
                    for (let k = 0; k < this.sSCookieCup3.length; k++) {
                        if (this.sSCookieCup3[k].isChecked) {
                            this.sSCookieCup3[k].isChecked = false;
                        }
                    }
                    this.sSCookieCup3[i].isChecked = true; 
            console.log(this.sSCookieCup3[i])
                }
       
    }

    removeSpaceshipCookie(i:number, spot:number) {

        if (spot == 0) {
            delete this.baseOrder.cookieCup1;
            this.sSCookieCup1[i].isChecked = false;
        } else
            if (spot == 1) {
                delete this.baseOrder.cookieCup2;
                this.sSCookieCup2[i].isChecked = false;

            } else
                if (spot == 2) {
                    delete this.baseOrder.cookieCup3;
                    this.sSCookieCup3[i].isChecked = false;
                }
        //this.cookieCups[i].isChecked = false;
    }

    addSpaceshipIceCream(i: number, spot: number, flavor: string) {

        this.addedOption = true;
        if (spot == 0) {
            this.baseOrder.scoop1 = flavor;
            for (let k = 0; k < this.iceCreamOptions1.length; k++) {
                if (this.iceCreamOptions1[k].isChecked) {
                    this.iceCreamOptions1[k].isChecked = false;
                }
            }
            this.iceCreamOptions1[i].isChecked = true;
        } else
            if (spot == 1) {
                this.baseOrder.scoop2 = flavor;
                for (let k = 0; k < this.iceCreamOptions2.length; k++) {
                    if (this.iceCreamOptions2[k].isChecked) {
                        this.iceCreamOptions2[k].isChecked = false;
                    }
                }
                this.iceCreamOptions2[i].isChecked = true;
            } else
                if (spot == 2) {
                    this.baseOrder.scoop3 = flavor;
                    for (let k = 0; k < this.iceCreamOptions3.length; k++) {
                        if (this.iceCreamOptions3[k].isChecked) {
                            this.iceCreamOptions3[k].isChecked = false;
                        }
                    }
                    this.iceCreamOptions3[i].isChecked = true;
                } 
    }

    removeSpaceshipIceCream(i:number, spot:number) {
        if (spot == 0) {
            delete this.baseOrder.scoop1;
            this.iceCreamOptions1[i].isChecked = false;
        } else
            if (spot == 1) {
                delete this.baseOrder.scoop2;
                this.iceCreamOptions2[i].isChecked = false;
            } else
                if (spot == 2) {
                    delete this.baseOrder.scoop3;
                    this.iceCreamOptions3[i].isChecked = false;
                } 

    }

    addSpaceshipTopping(i) {
        this.addedOption = true;
        this.baseOrder.topping = this.toppingOptions[i].name;
        for (let k = 0; k < this.toppingOptions.length; k++) {
            if (this.toppingOptions[k].isChecked) {
                this.toppingOptions[k].isChecked = false;
            }
        }
        this.toppingOptions[i].isChecked = true;

    }

    removeSpaceshipTopping(i) {
      delete this.baseOrder.topping;
      this.toppingOptions[i].isChecked = false;
    }

    addSpaceshipSyrup(i) {
        this.addedOption = true;
        this.baseOrder.syrup = this.syrupOptions[i].name;
        for (let k = 0; k < this.syrupOptions.length; k++) {
            if (this.syrupOptions[k].isChecked) {
                this.syrupOptions[k].isChecked = false;
            }
        }
        this.syrupOptions[i].isChecked = true;
    }

    removeSpaceshipSyrup(i) {
       delete this.baseOrder.syrup 
       this.syrupOptions[i].isChecked = false;
    }

    addTierrita(i, flavor: string, type) {
        this.baseOrder.length = 0;

        for (let k = 0; k < this.prodFlavors.length; k++) {
            if (this.prodFlavors[k].isChecked) {
                this.prodFlavors[k].isChecked = false;
            }
        }
        this.prodFlavors[i].isChecked = true;
        this.baseOrder.push({
            type: type,
            flavor: flavor 
        })
        console.log(this.baseOrder)
    }

    removeTierrita(i, flavor: string) { 
        this.prodFlavors[i].isChecked = false;
        this.baseOrder.length = 0;
    }

    async checkForUser() {
        if (!this.user) {
            const alert = await this.alertCtrl.create({
                header: 'Desea crear una cuenta o continuar como invitado?',
                //  backdropDismiss: false, 
                buttons: [
                    {
                        text: 'Crear',
                        role: 'cancel',
                        handler: () => {
                            this.closeModal();
                            this.router.navigate(['create-account']);
                            return;
                        }
                    },
                    {
                        text: 'Invitado',
                        role: 'confirm',
                        handler: () => {
                            this.generateGuestAcct();
                        }
                    }
                ]
            });

            await alert.present();

            // const { role } = await alert.onDidDismiss();
        } else {
            this.addToCart();
        }
    }

    async generateGuestAcct() {
        var number = (Math.floor(100000 + Math.random() * 900000)).toString(); 

        const user = new Parse.User();
        user.set('username', 'invitado' + number + '@cookie.com');
        user.set('password', number);
        user.set('fName', '');
        user.set('lName', '');
        user.set('email', 'invitado' + number + '@cookie.com');
        await user.save();
        await Parse.User.logIn(user.get('username'), user.get('password'));
        this.user = user;
        this.getUser(); 
        this.addToCart();
             
    }


    async addToCart() {
        if (!this.storeIsOpen) {
            this.service.presentToast('Cookie Planet aun no ha abierto. Porfavor verificar el horario e intentar de nuevo en otro momento');
            return;
        }

        var cart;

        if (this.product.get('name') == 'Cookie Cups') {
            var checked = await this.checkCurrentCookieOrder();
            if (checked) {
                cart =
                {
                    product: this.product.get('name'),
                    details: this.cookieCupsOrder,
                    price: this.prodPrice,
                    id: this.product.id
                }
                if (this.extrasAdded.length != 0) {
                    cart.extras = this.extrasAdded
                }
            } else {
                return;
            }

        } else

            if (this.product.get('name') == 'Galaxy') {
                var passed = await this.checkGalaxyOrder();
                if (passed) {
                     
                    cart = {
                        product: this.product.get('name'),
                        details: this.galaxyCookieOrder,
                        price: this.prodPrice,
                        id: this.product.id

                    }
                    if (this.extrasAdded.length != 0) {
                        cart.extras = this.extrasAdded
                    }
                } else {
                    return;
                }

            } else

            if (this.product.get('name') == 'Twinkle Star') {
                
                var passed = await this.checkGalaxyOrder();
                if (passed) {
                     
                    cart = {
                        product: this.product.get('name'),
                        details: this.twinkleStarOrder,
                        price: this.prodPrice,
                        id: this.product.id 
                    }
                    if (this.extrasAdded.length != 0) {
                        cart.extras = this.extrasAdded
                    }
                } else {
                    return;
                }

            } else
               // if (this.showOptions) {

                if (this.product.get('name') == 'Galaxy Plus') {
                       
                        var passed = await this.checkGalaxyOrder();
                      console.log(passed)
                        if (passed) {
                            cart = {
                                product: this.product.get('name'),
                                details: this.galaxyPlusCookieOrder,
                                topping: this.baseOrder[0].flavor,
                                price: this.prodPrice,
                                id: this.product.id

                            }
                            if (this.extrasAdded.length != 0) {
                                cart.extras = this.extrasAdded
                            }
                        } else {
                            return;
                        } 
                    } else { 
                    
                    var passed = await this.checkBaseOrder(); 
                        if (passed) {
                            cart = {
                                product: this.product.get('name'),
                                details: this.baseOrder,
                                price: !this.prodPrice ? this.product.get('details')[0].price : this.prodPrice,
                                id: this.product.id 
                            }
                            if (this.extrasAdded.length != 0) {
                                cart.extras = this.extrasAdded
                            }
                        } else {
                            return;
                        }
                    }
              /*  }
                    else {
                        cart = {
                            product: this.product.get('name'),
                            details: this.baseOrder,
                            price: this.prodPrice
                        }
                    }*/ 
                         

        cart.quantity = this.prodQuantity;
        console.log(cart)
        this.user.add('cart', cart);
        await this.user.save(); 
        await this.service.presentToast('Añadido al carrito exitosamente');
        this.closeModal();
    }
}
