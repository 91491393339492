<ion-header class="">
    <ion-toolbar style="text-align: center;">
        <ion-buttons slot="start">
            <ion-button color="dark" (click)="closeModal()">
                <ion-icon slot="icon-only"  name="close"  size="large" color="dark"></ion-icon>
            </ion-button>
        </ion-buttons>
        <ion-text style="font-size:30px;">{{product?.get('name')}}</ion-text>
        <ion-buttons slot="end">
            <ion-button (click)="addToFavorites()" size="large" class="favBtn" color="dark">
                <ion-icon [name]="heartType" id="heart" slot="icon-only" mode="md" ></ion-icon>
            </ion-button>
        </ion-buttons>
    </ion-toolbar>
</ion-header>
<ion-content scrollEvents="true" id="content" [fullscreen]="true">
<ion-row class="contentRow">
  <ion-col class="prodBack" size="11">

    <!-- <ion-row style="justify-content: left">
      <ion-col size="1" (click)="closeModal()">
        <ion-icon  name="close"  size="large" color="dark"></ion-icon>
      </ion-col>
      <ion-col size="9" style="text-align: center;">
        <ion-text style="font-size:30px;">{{product?.get('name')}}</ion-text>
      </ion-col>
      <ion-col size="1">
        <ion-icon [name]="heartType" id="heart" size="large" mode="md" (click)="addToFavorites()"></ion-icon>
      </ion-col>
    </ion-row> -->

    <ion-row style="justify-content:center;">
        <ion-col size="12">
            <ion-slides>
                <ion-slide>
                    <img class="image" src="{{product?.get('image').url()}}" />
                </ion-slide>
            </ion-slides>
        </ion-col>

        <ion-col size="11" style="text-align: left">
            <ion-text style="font-size: 12px">Descripci&oacute;n<br /></ion-text>
            <ion-spinner *ngIf="loading" color="danger"></ion-spinner>
            <ion-text style="font-size:15px;">{{product?.get('description')}}<br></ion-text>
            <!-->ion-text style="color:#F5AF51; font-size:20px">Precios<br></ion-text>
        <ion-text *ngFor="let detail of product?.get('details')">{{detail.amount}} x ${{detail.price}}<br></ion-text-->
           
        </ion-col>

        <!--////////////////////////////Cookie Cups////////////////////////////////////////////-->

        <ion-col size="12" *ngIf="product?.get('name') == 'Cookie Cups'">
            <ion-row style="justify-content:left">
                <ion-col>
                    <ion-text>&#191;Cuantas galletas?</ion-text>
                    <ion-row style="border: 2px solid lightgrey">
                        <ion-col *ngFor="let detail of product?.get('details'); let i = index">
                            <ion-item lines="none" style="--background:#ffffff00" (click)="setCookieQuantity(detail.amount)">
                                <ion-icon name="ellipse-outline" color="dark" slot="start" id="cookies{{detail.amount}}"></ion-icon>
                                <ion-label>{{detail.amount}} - ${{detail.price}}</ion-label>
                            </ion-item>
                        </ion-col>
                        <!--ion-col>
                        <ion-item lines="none" style="--background:#ffffff00" (click)="setCookieQuantity(12)">
                            <ion-icon name="ellipse-outline" color="dark" *ngIf="!twelveChecked" slot="start"></ion-icon>
                            <ion-icon name="checkmark-circle" color="primary" *ngIf="twelveChecked" slot="start"></ion-icon>
                            <ion-label>12</ion-label>
                        </ion-item>
                    </ion-col-->
                    </ion-row>
                </ion-col>
            </ion-row>

            <ion-row style="justify-content:left">
                <ion-col>
                    <ion-text>Selecciona tus sabores</ion-text>
                    <ion-row>
                        <ion-col style="border: 2px solid lightgrey; max-height: 350px; overflow-y: scroll;" size="12">
                            <ion-row>
                                <ion-col *ngFor="let cookie of prodFlavors; let i = index" size="12">
                                    <ion-item lines="none" style="--background:#ffffff00">
                                        <ion-icon name="ellipse-outline" color="dark" *ngIf="!cookie.isChecked" slot="start" (click)="addToCookieOrder(i)"></ion-icon>
                                        <ion-icon name="checkmark-circle" color="primary" *ngIf="cookie.isChecked" slot="start" (click)="addToCookieOrder(i)"></ion-icon>
                                        <ion-label>{{cookie.flavor}}</ion-label>
                                        <ion-select #C interface="popover" *ngIf="cookie.isChecked" (ionChange)="updateCookieQty(cookie.flavor, C.value)" placeholder="1" id="{{cookie.flavor}}">
                                            <ion-select-option value="1">1</ion-select-option>
                                            <ion-select-option value="2">2</ion-select-option>
                                            <ion-select-option value="3">3</ion-select-option>
                                            <ion-select-option value="4">4</ion-select-option>
                                            <ion-select-option value="5">5</ion-select-option>
                                            <ion-select-option value="6">6</ion-select-option>
                                            <ion-select-option value="7">7</ion-select-option>
                                            <ion-select-option value="8">8</ion-select-option>
                                            <ion-select-option value="9">9</ion-select-option>
                                            <ion-select-option value="10">10</ion-select-option>
                                            <ion-select-option value="11">11</ion-select-option>
                                            <ion-select-option value="12">12</ion-select-option>
                                        </ion-select>
                                    </ion-item>
                                </ion-col>
                            </ion-row>
                        </ion-col>
                    </ion-row>
                    <ion-row *ngIf="prodPrice != 0">
                        <ion-col size="12" style="text-align:left">
                            <ion-text>Resumen  </ion-text>
                        </ion-col>
                        <ion-col style="border: 2px solid lightgrey; text-align: left; max-height: 150px; overflow-y: scroll; font-size: 13px" *ngIf="prodPrice != 0" size="12">
                            <ion-row *ngFor="let order of cookieCupsOrder">
                                <ion-col style="color: lightgrey; text-align: left; ">
                                    <ion-text>{{order.qty}} x {{order.cookie}}</ion-text>
                                </ion-col>
                            </ion-row>
                        </ion-col>
                        <!-->ion-col>
                            <ion-text>Precio</ion-text>
                            <ion-row>
                                <ion-col style="border: 2px solid lightgrey; text-align: center">
                                    <ion-text style="font-size: 25px; font-weight: bold; color: forestgreen">${{prodPrice}} <br /></ion-text>

                                </ion-col>
                            </ion-row>
                        </!ion-col-->
                    </ion-row>
                </ion-col>
            </ion-row>
        </ion-col>

        <!--////////////////////////////Galaxy -- Galaxy Plus////////////////////////////////////////////-->

        <ion-col size="12" *ngIf="product?.get('name') == 'Galaxy'">
            <ion-row style="justify-content:left">
                <ion-col>
                    <ion-text>&#191;Cuantas galletas?</ion-text>
                    <ion-row style="border: 2px solid lightgrey; justify-content: left">
                        <ion-col *ngFor="let detail of product?.get('details'); let i = index" size="7">
                            <ion-item lines="none" style="--background:#ffffff00" (click)="setGalaxyQuantity(detail.amount, detail.price)">
                                <ion-icon name="ellipse-outline" color="dark" slot="start" id="cookies{{detail.amount}}"></ion-icon>
                                <ion-label>{{detail.amount}} - ${{detail.price}}</ion-label>
                            </ion-item>
                        </ion-col>
                    </ion-row>
                </ion-col>
            </ion-row>

            <ion-row style="justify-content:left">
                <ion-col>
                    <ion-text>Selecciona tus sabores</ion-text>
                    <ion-row>
                        <ion-col style="border: 2px solid lightgrey; max-height: 350px; overflow-y: scroll;" size="12">
                            <ion-row *ngFor="let cookie of prodFlavors; let i = index">
                                <ion-col>
                                    <ion-item lines="none" style="--background:#ffffff00">

                                        <ion-icon name="ellipse-outline" color="dark" *ngIf="!prodFlavors[i].isChecked" slot="start" (click)="addGalaxyToOrder(i)"></ion-icon>
                                        <ion-icon name="checkmark-circle" color="primary" *ngIf="prodFlavors[i].isChecked" slot="start" (click)="addGalaxyToOrder(i)"></ion-icon>
                                        <ion-label>{{cookie.flavor}}</ion-label>
                                        <ion-select #G interface="popover" *ngIf="cookie.isChecked" (ionChange)="updateGalaxyQty(cookie.flavor, G.value)" placeholder="1" id="{{cookie.flavor}}">
                                            <ion-select-option value="1">1</ion-select-option>
                                            <ion-select-option value="2">2</ion-select-option>
                                            <ion-select-option value="3">3</ion-select-option>
                                            <ion-select-option value="4">4</ion-select-option>
                                            <ion-select-option value="5">5</ion-select-option>
                                            <ion-select-option value="6">6</ion-select-option>
                                        </ion-select>
                                    </ion-item>
                                </ion-col>
                            </ion-row>
                        </ion-col>
                    </ion-row>
                    <!--ion-row *ngIf="prodPrice != 0">
                        <ion-col size="12" style="text-align:left">
                            <ion-text>Resumen  </ion-text>
                        </ion-col>
                        <ion-col style="border: 2px solid lightgrey; text-align: left; max-height: 150px; overflow-y: scroll; font-size: 13px" size="12">
                            <ion-row *ngFor="let order of galaxyCookieOrder">
                                <ion-col style="color: lightgrey; text-align: left; ">
                                    <ion-text>{{order.qty}} x {{order.cookie}}</ion-text>
                                </ion-col>
                            </ion-row>
                        </ion-col>
                        <>ion-col>
                            <ion-text>Precio</ion-text>
                            <ion-row>
                                <ion-col style="border: 2px solid lightgrey; text-align: center">
                                    <ion-text style="font-size: 25px; font-weight: bold; color: forestgreen">${{prodPrice}} <br /></ion-text>

                                </ion-col>
                            </ion-row>
                        </ion-col>

                    </ion-row-->
                </ion-col>
            </ion-row>
        </ion-col>


<!--////////////////////////////Twinkle Star ////////////////////////////////////////////-->


        <ion-col size="12" *ngIf="product?.get('name') == 'Twinkle Star'">
            <ion-row style="justify-content:left">
                <ion-col>
                    <ion-text>&#191;Cuantas galletas?</ion-text>
                    <ion-row style="border: 2px solid lightgrey; justify-content: left">
                        <ion-col *ngFor="let detail of product?.get('details'); let i = index" size="7">
                            <ion-item lines="none" style="--background:#ffffff00" (click)="setTwinkleQuantity(detail.amount, detail.price)">
                                <ion-icon name="ellipse-outline" color="dark" slot="start" id="twinkle{{detail.amount}}"></ion-icon>
                                <ion-label>{{detail.amount}} - ${{detail.price}}</ion-label>
                            </ion-item>
                        </ion-col>
                    </ion-row>
                </ion-col>
            </ion-row>

            <ion-row style="justify-content:left">
                <ion-col>
                    <ion-text>Selecciona tus sabores</ion-text>
                    <ion-row>
                        <ion-col style="border: 2px solid lightgrey; max-height: 350px; overflow-y: scroll;" size="12">
                            <ion-row *ngFor="let cookie of prodFlavors; let i = index">
                                <ion-col>
                                    <ion-item lines="none" style="--background:#ffffff00">

                                        <ion-icon name="ellipse-outline" color="dark" *ngIf="!prodFlavors[i].isChecked" slot="start" (click)="addTwinkleToOrder(i)"></ion-icon>
                                        <ion-icon name="checkmark-circle" color="primary" *ngIf="prodFlavors[i].isChecked" slot="start" (click)="addTwinkleToOrder(i)"></ion-icon>
                                        <ion-label>{{cookie.flavor}}</ion-label>
                                        <ion-select #G interface="popover" *ngIf="cookie.isChecked" (ionChange)="updateTwinkleQty(cookie.flavor, G.value)" placeholder="1" id="{{cookie.flavor}}">
                                            <ion-select-option value="1">1</ion-select-option>
                                            <ion-select-option value="2">2</ion-select-option>
                                            <ion-select-option value="3">3</ion-select-option>
                                            <ion-select-option value="4">4</ion-select-option>
                                            <ion-select-option value="5">5</ion-select-option>
                                            <ion-select-option value="6">6</ion-select-option>
                                            <ion-select-option value="7">7</ion-select-option>
                                            <ion-select-option value="8">8</ion-select-option>
                                        </ion-select>
                                    </ion-item>
                                </ion-col>
                            </ion-row>
                        </ion-col>
                    </ion-row>
                    <!--ion-row *ngIf="prodPrice != 0">
                        <ion-col size="12" style="text-align:left">
                            <ion-text>Resumen  </ion-text>
                        </ion-col>
                        <ion-col style="border: 2px solid lightgrey; text-align: left; max-height: 150px; overflow-y: scroll; font-size: 13px" size="12">
                            <ion-row *ngFor="let order of galaxyCookieOrder">
                                <ion-col style="color: lightgrey; text-align: left; ">
                                    <ion-text>{{order.qty}} x {{order.cookie}}</ion-text>
                                </ion-col>
                            </ion-row>
                        </ion-col>
                        <>ion-col>
                            <ion-text>Precio</ion-text>
                            <ion-row>
                                <ion-col style="border: 2px solid lightgrey; text-align: center">
                                    <ion-text style="font-size: 25px; font-weight: bold; color: forestgreen">${{prodPrice}} <br /></ion-text>

                                </ion-col>
                            </ion-row>
                        </ion-col>

                    </ion-row-->
                </ion-col>
            </ion-row>
        </ion-col>

        <ion-col size="12" *ngIf="product?.get('name') == 'Galaxy Plus'">
            <ion-row style="justify-content:left">
                <ion-col>
                    <ion-text>&#191;Cuantas galletas?</ion-text>
                    <ion-row style="border: 2px solid lightgrey; justify-content: left">
                        <ion-col *ngFor="let detail of product?.get('details'); let i = index" size="7">
                            <ion-item lines="none" style="--background:#ffffff00" (click)="setGalaxyPlusQuantity(detail.amount, detail.price)">
                                <ion-icon name="ellipse-outline" color="dark" slot="start" id="cookies{{detail.amount}}"></ion-icon>
                                <ion-label>{{detail.amount}} - ${{detail.price}}</ion-label>
                            </ion-item>
                        </ion-col>
                    </ion-row>
                </ion-col>
            </ion-row>

            <ion-row style="justify-content:left">
                <ion-col>
                    <ion-text>Selecciona tus sabores</ion-text>
                    <ion-row>
                        <ion-col style="border: 2px solid lightgrey; max-height: 350px; overflow-y: scroll;" size="12">
                            <ion-row *ngFor="let cookie of prodFlavors; let i = index">
                                <ion-col>
                                    <ion-item lines="none" style="--background:#ffffff00"> 
                                        <ion-icon name="ellipse-outline" color="dark" *ngIf="!prodFlavors[i].isChecked" slot="start" (click)="addGalaxyPlus(i)"></ion-icon>
                                        <ion-icon name="checkmark-circle" color="primary" *ngIf="prodFlavors[i].isChecked" slot="start" (click)="addGalaxyPlus(i)"></ion-icon>
                                        <ion-text style="font-size:13px;">{{cookie.flavor}}</ion-text>
                                        <ion-select #G interface="popover" *ngIf="cookie.isChecked" (ionChange)="updateGalaxyPlusQty(cookie.flavor, G.value)"  placeholder="1" id="{{cookie.flavor}}" slot="end">
                                            <ion-select-option value="1">1</ion-select-option>
                                            <ion-select-option value="2">2</ion-select-option>
                                            <ion-select-option value="3">3</ion-select-option>
                                            <ion-select-option value="4">4</ion-select-option>
                                            <ion-select-option value="5">5</ion-select-option>
                                            <ion-select-option value="6">6</ion-select-option>
                                        </ion-select>
                                    </ion-item>
                                </ion-col>
                            </ion-row>
                        </ion-col>
                    </ion-row>
                    <!--ion-row *ngIf="prodPrice != 0">
                        <ion-col size="12" style="text-align:left">
                            <ion-text>Resumen  </ion-text>
                        </ion-col>
                        <ion-col style="border: 2px solid lightgrey; text-align: left; max-height: 150px; overflow-y: scroll; font-size: 13px" size="12">
                            <ion-row *ngFor="let order of galaxyPlusCookieOrder">
                                <ion-col style="color: lightgrey; text-align: left; ">
                                    <ion-text>{{order.qty}} x {{order.cookie}}</ion-text>
                                </ion-col>
                            </ion-row>
                        </ion-col>
                        <ion-col>
                            <ion-text>Precio</ion-text>
                            <ion-row>
                                <ion-col style="border: 2px solid lightgrey; text-align: center">
                                    <ion-text style="font-size: 25px; font-weight: bold; color: forestgreen">${{prodPrice}} <br /></ion-text>

                                </ion-col>
                            </ion-row>
                        </ion-col>
                    </ion-row-->
                </ion-col>
            </ion-row>
        </ion-col>
 

        <!--////////////////////////////Rest Products////////////////////////////////////////////-->
        <ion-col size="12" *ngIf="product?.get('name') == 'Tierra'">
            <ion-row>
                <ion-col>
                    <ion-text style="font-weight: normal;">Escoge un sabor</ion-text>
                    <ion-row>
                        <ion-col style="border: 2px solid lightgrey; max-height: 350px; overflow-y: scroll;" size="12">
                            <ion-row lines="none" *ngFor="let tierra of prodFlavors; let i = index">
                                <ion-col>
                                    <ion-item lines="none">
                                        <ion-icon name="ellipse-outline" color="dark" slot="start" *ngIf="!tierra.isChecked" (click)="addTierrita(i, tierra.flavor,'Tierra')"></ion-icon>
                                        <ion-icon name="checkmark-circle" color="primary" *ngIf="tierra.isChecked" slot="start" (click)="removeTierrita(i,tierra.flavor)"></ion-icon>
                                        <ion-label>{{tierra.flavor}}</ion-label>
                                    </ion-item>
                                </ion-col>
                            </ion-row>
                        </ion-col>
                    </ion-row>
                </ion-col> 
            </ion-row> 
        </ion-col>

        <ion-col size="12" *ngIf="prodModifiers.length != 0"> 
            <ion-accordion-group expand="inset" #accordionGroup>
                <ion-accordion *ngFor="let mod of prodModifiers; let k = index" style="text-align:left" value="{{mod.name}}" id="{{mod.name}}">
                    <ion-item slot="header" color="light">
                        <ion-label>{{mod.name}}</ion-label>
                    </ion-item>
                    <div slot="content" style="max-height: 250px; overflow-y:scroll">
                        <ion-list>
                            <ion-radio-group>
                                <ion-item *ngFor="let option of mod.options; let i = index" lines="none">
                                    <ion-radio name="ellipse-outline" color="dark" slot="start" (click)="addOption(k,i,mod.name)"></ion-radio>
                                    <ion-text *ngIf="option.isPremium" style="font-size: 13px;" color="warning">{{option.name}} (+${{option.price.toFixed(2)}})</ion-text>
                                    <ion-text *ngIf="!option.isPremium" style="font-size: 13px;" color="dark">{{option.name}}</ion-text>
                                </ion-item>
                            </ion-radio-group>
                        </ion-list>
                    </div>
                </ion-accordion>
            </ion-accordion-group>
        </ion-col>

        <ion-col size="12" *ngIf="allowedExtras.length != 0">
            <ion-row class="extraRow"> 
                <ion-col style="text-align:left">
                    <ion-text color="light">A&ntilde;adir extra?</ion-text> 
                </ion-col>
            </ion-row> 
            <ion-row>
                <ion-col size="12">
                    <ion-accordion-group multiple="true" expand="inset">
                        <ion-accordion *ngFor="let mod of allowedExtras; let k = index" style="text-align:left">
                            <ion-item slot="header" color="light">
                                <ion-label>{{mod.name}}</ion-label>
                            </ion-item>
                                <div slot="content" style="max-height: 160px; overflow-y:scroll"> 
                                    <ion-item *ngFor="let option of mod.options; let i = index" (click)="addExtras(allowedExtras[k].name, allowedExtras[k].options[i])" lines="none">
                                        <ion-checkbox mode="ios" name="ellipse-outline" color="dark" slot="start" ></ion-checkbox>
                                        <ion-text style="font-size: 13px;" color="dark">{{option.name}} (+${{option.price.toFixed(2)}})</ion-text>
                                    </ion-item> 
                                </div>
                        </ion-accordion>
                    </ion-accordion-group>
                </ion-col>
            </ion-row>
        </ion-col>

        <ion-col size="7" *ngIf="productDetails.length > 1 && product?.get('name') != 'Galaxy' && product?.get('name') != 'Galaxy Plus' && product?.get('name') != 'Cookie Cups'" style="text-align: center;">
            <ion-radio-group [value]="0" color="light">
                <ion-item *ngFor="let detail of productDetails; let i = index" lines="none" (click)="setProductPrice(i)">
                    <ion-radio slot="start" [value]="i" style="--color:black"></ion-radio>
                    <ion-label slot="start">{{detail.amount}}</ion-label>
                    <ion-label slot="end">${{detail.price}}</ion-label>
                  </ion-item>
            </ion-radio-group>
        </ion-col> 

        <ion-col size="7" *ngIf="prodPrice !==0 " style="text-align: center">
            <ion-text style="font-size: 25px; font-weight: bold; color: forestgreen">${{prodPrice}}</ion-text>
        </ion-col>

        <ion-col size="6">
            <ion-item lines="none">
                <ion-label>Cantidad:</ion-label>
                <ion-input style="--background:#f0f0f0; text-align:center" type="number" [(ngModel)]="prodQuantity"></ion-input>
            </ion-item>
            <ion-button expand="block" shape="round" color="tertiary" (click)="checkForUser()">
                Add to cart
                <ion-icon slot="start" color="light" name="cart"></ion-icon>
            </ion-button>
        </ion-col>
    </ion-row>
  </ion-col>
</ion-row> 

</ion-content>