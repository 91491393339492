import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular'; 


import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';

import { ProductPageModule } from './product/product.module'; 
import { AdminProdModalPageModule } from './adminProdModal/adminProdModal.module';
import { pageAnimation } from './pageAnimation';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment'; 
import { Storage } from '@ionic/storage';
import { HttpClientModule } from '@angular/common/http';   

@NgModule({
    declarations: [AppComponent],
    imports: [
        BrowserModule,
        IonicModule.forRoot({
            navAnimation: pageAnimation
        }),
        AppRoutingModule,
        ProductPageModule,
        AdminProdModalPageModule,
        HttpClientModule,
        ServiceWorkerModule.register('ngsw-worker.js', {
          enabled: environment.production,
          // Register the ServiceWorker as soon as the application is stable
          // or after 30 seconds (whichever comes first).
          registrationStrategy: 'registerWhenStable:30000'
        }), 
    ],
    providers: [ 
        Storage, 
        { provide: RouteReuseStrategy, useClass: IonicRouteStrategy }
    ],
    bootstrap: [AppComponent]
})
export class AppModule {}
