import { Injectable } from '@angular/core';
import Parse from 'parse';
import { ToastController, Animation, AnimationController, AlertController, Platform } from '@ionic/angular';
import { SwUpdate } from '@angular/service-worker';
import { Storage } from '@ionic/storage'; 
 

@Injectable({
  providedIn: 'root'
})
export class CookieService {
    public selectedProd: any;
    public loading: boolean = false;
    public user: any;

    public selectedProdId: any;
    public selectedCat: any;

    public adminSelectedProd: any;

    public dismissedDessertPup: boolean = false;
    public dismissedFroShakePup: boolean = false;
    public dismissedHomePup: boolean = false;

    public cashAudio: any;
     

    constructor(
        private toastCtrl: ToastController,
        private animationCtrl: AnimationController,
        private updates: SwUpdate,
        private alertCtrl: AlertController,
        private storage: Storage, 
        private platform: Platform, 
    ) {
      if (updates) {

            updates.checkForUpdate();
            updates.available.subscribe(event => {

                if (event.available) {
                    this.getUpdates()
                }

            });
        }

        this.storage.create();
        this.platform.ready().then(() => {
            this.loadAudio();
            
        });
         

    } 
     

    public async getUpdates() {
         window.location.reload(); 
    }

    public async updateApp(message) {

        const alert = await this.alertCtrl.create({
            cssClass: 'alertclass',
            message: message,
            keyboardClose: true,
            backdropDismiss: false,
            buttons: [
                {
                    text: 'Si',
                    handler: () => {
                        alert.dismiss(true);
                        return false
                    }
                }, {
                    text: 'No',
                    handler: () => {
                        alert.dismiss(false);
                        return false

                    }
                }
            ]
        });
        return alert;
    }


    async presentToast(message) {
        const toast = await this.toastCtrl.create({
            message: message,
            duration: 2000,
            cssClass: 'toasting'
        });
        await toast.present();
    }

    async getUser() {
        return await Parse.User.current();
    }


    async loadAudio() {
        this.cashAudio = new Audio();
        this.cashAudio.src = 'assets/audio/cash.mp3';
       // this.cashAudio.play();
    }
     
}
