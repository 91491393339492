import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full'
  },
  {
    path: 'home',
    loadChildren: () => import('./home/home.module').then(m => m.HomePageModule)
  },
  {
      path: 'dessert',
      loadChildren: () => import('./dessert/dessert.module').then(m => m.DessertPageModule)
  }, 
  {
    path: 'create-account',
    loadChildren: () => import('./create-account/create-account.module').then( m => m.CreateAccountPageModule)
  },
  {
    path: 'login',
    loadChildren: () => import('./login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: 'account',
    loadChildren: () => import('./account/account.module').then( m => m.AccountPageModule)
  }, 
  {
    path: 'admin/admin-panel',
    loadChildren: () => import('./admin-panel/admin-panel.module').then( m => m.AdminPanelPageModule)
    },
    {
    path: 'admin/create-product',
    loadChildren: () => import('./admincreateproduct/admincreateproduct.module').then( m => m.AdmincreateproductPageModule)
  },
  {
    path: 'employee-panel',
    loadChildren: () => import('./employee-panel/employee-panel.module').then( m => m.EmployeePanelPageModule)
  },
  {
    path: 'follow-us',
    loadChildren: () => import('./social-media/social-media.module').then( m => m.SocialMediaPageModule)
  },
  {
    path: 'cart',
    loadChildren: () => import('./cart/cart.module').then( m => m.CartPageModule)
  },
  {
    path: 'checkout',
    loadChildren: () => import('./checkout/checkout.module').then( m => m.CheckoutPageModule)
  },
  {
    path: 'admin/order-panel',
    loadChildren: () => import('./order-panel/order-panel.module').then( m => m.OrderPanelPageModule)
  },
  {
    path: 'orderSuccess',
    loadChildren: () => import('./order-success/order-success.module').then( m => m.OrderSuccessPageModule)
  }



];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules})
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
