import { Component, ContentChild } from '@angular/core';
import Parse from 'parse';
import { Platform, Animation, AnimationController } from '@ionic/angular';
import { SplashScreen } from '@capacitor/splash-screen';
import { StatusBar, Style } from '@capacitor/status-bar';
import { CookieService } from './cookie.service'; 

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent { 

  constructor(
      private platform: Platform,  
      private animationCtrl: AnimationController,
      public service: CookieService,
  ) {
      
      this.initializeApp();

    // Initialize Parse
      Parse.initialize("LXNUsU9pnpTos6PIJB1a5WP70yiEGFSgSiG9pyKN", 
      "W5yFNJLapKY7AIgcxFbvGmZeuqkHZmGR5hZy9sbM"); //PASTE HERE YOUR Back4App APPLICATION ID AND YOUR JavaScript KEY
      Parse.serverURL = "https://cookieplanetpr.b4a.io/";

  }

    initializeApp() { 

        this.platform.ready().then(() => { 
           if(this.platform.is('cordova')){
            StatusBar.hide();
            SplashScreen.hide(); 
           } 
    });
    }



     
}
